<template>
  <v-container id="printDiscounts" fluid tag="section">
    <alert-notification :message="alert" />
    <v-banner color="secondary" icon="mdi-firefox" dark outlined rounded class="mb-6 ma-auto" style="max-width: 500px;">
      <div>
        <h1>Utilisez Firefox pour l'impression</h1>
      </div>
    </v-banner>
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.printDiscounts.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.printDiscounts.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="discounts" :search="table.search" :loading="loading">
        <template #[`item.quantity`]="{ item }">
          <span v-if="Array.isArray(item.codes) && item.codes.length !== item.quantity">{{ item.codes.length }} / {{ item.quantity }}</span>
          <span v-else>{{ item.quantity }}</span>
        </template>
        <template #[`item.toDate`]="{ item }">
          <span>{{ formatDate(item.toDate) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <span class="pr-3">
            <v-icon medium :title="$t('admin.sub.printDiscounts.action.print.title')" @click="print(item)">
              mdi-printer
            </v-icon>
          </span>
          <span>
            <v-icon medium :title="$t('admin.sub.printDiscounts.action.archive.title')" @click="archive(item)">
              mdi-archive
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'AdminPrintDiscounts',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.printDiscounts.table.column.id'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'id'
            },
            {
              text: this.$i18n.t('admin.sub.printDiscounts.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('admin.sub.printDiscounts.table.column.description'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'description'
            },
            {
              text: this.$i18n.t('admin.sub.printDiscounts.table.column.quantity'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'quantity'
            },
            {
              text: this.$i18n.t('admin.sub.printDiscounts.table.column.expiration'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'toDate'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        discounts: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      print (discount) {
        window.open(process.env.VUE_APP_BASE_APP_URL + 'discount.html?id=' + String(discount.id)
                      + '&api=' + encodeURIComponent(String(process.env.VUE_APP_BASE_API_URL + 'discount?id=' + String(discount.id))),
                    'printDiscount', 'location=yes,height=570,width=520,resizable,scrollbars,status');
      },
      async archive (discount) {
        if (confirm(this.$i18n.t('admin.sub.printDiscounts.action.archive.confirm'))) {
          this.loading = true;
          try {
            await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'discount?id=' + String(discount.id));
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;

          await this.refreshData();
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'discounts');
          if (response.data !== undefined && response.data.discounts !== undefined) {
            this.discounts = response.data.discounts;
          }

          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
